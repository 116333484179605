<script type="text/javascript" src="cordova.js"></script>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import DatePicker from "vue2-datepicker";
//import NumberInputSpinner from "vue-number-input-spinner";
import Swal from "sweetalert2";
import axios from 'axios';
import error403 from '../utility/error-403';

import {
  required,
  requiredIf,
  email,
  sameAs,
  minLength,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum,
  //requiredIf
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "AutoShop customer registration",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, error403 /*DatePicker, NumberInputSpinner*/ },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "",
      items: [
        {
          text: "UI Elements",
          href: "/"
        },
        {
          text: "Tabs & Accordions",
          active: true
        }
      ],
      
      form: {
        nm_company:                 "",
        de_companyShortAddress:     "",
        de_companyContactEmail:     "",
        num_companyContactPhone:    "",
        id_country:                 "",
        id_state:                   "",
        nm_city:                    "",
        de_address:                 "",
        de_siteRepresentantEmail:   "",
        de_siteHumanResourcesEmail: "",
        de_siteContactEmail:        "",
        nm_site:                    "",
        nm_employee:                "",
        nm_lastName:                "",
        num_employeeAge:            "",
        id_gender:                  "",
        id_role:                    "",
      },
      platform:     "",

      okPassword:   true,
      submitted:    false,
      validToken:   true,
      validAccess:  true,
      user:         {},
      countries:    [],
      states:       [],
      sites:        [],
      roles:        [],
    };
  },
  validations: {
    form: {
      nm_company:                     { required },
      de_companyShortAddress:         { required },
      de_companyContactEmail:         { required, email },
      num_companyContactPhone:        { required },
      id_country:                     { required },
      id_state:                       { required },
      de_address:                     { required },
      de_siteRepresentantEmail:       { required, email },
      de_siteHumanResourcesEmail:     { required, email },
      de_siteContactEmail:            { required, email },
      nm_site:                        { required },
      nm_employee:                    { required },
      nm_lastName:                    { required },
      num_employeeAge:                { required },
      id_gender:                      { required },
      id_role:                        { required },
      de_password:                    {
        required: requiredIf(function(){return !this.$route.params.id;}),
        minLength: minLength(8),
      },
      de_rePassword:                  { required: requiredIf(function(){return !this.$route.params.id;}), sameAsPassword: sameAs("de_password") },
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.getRoles();
    this.getCountries();
    document.addEventListener("deviceready", this.onDeviceReady, false);
  },
  methods: {
    onDeviceReady(){
      let platform = device.platform;

      if(platform){
        this.platform = platform
      }
    },

    setOtherEmails(){
      this.form.de_siteRepresentantEmail     = this.form.de_companyContactEmail;
      this.form.de_siteHumanResourcesEmail   = this.form.de_companyContactEmail;
      this.form.de_siteContactEmail          = this.form.de_companyContactEmail;
    },

    validatePassword(event){
      const containsUppercase = /[A-Z]/.test(event.target.value);
      const containsLowercase = /[a-z]/.test(event.target.value);
      const containsNumber = /[0-9]/.test(event.target.value);
      const containsSpecial = /[#?!@$%^&*-]/.test(event.target.value);

      if( this.form.de_password != ""){
        if( containsUppercase && containsLowercase && containsNumber && containsSpecial ){
          this.okPassword = true;
        }else{
          this.okPassword = false;
        }
      }else{
        this.okPassword = false;
      }
      //return this.okPassword;
    },

    async getRoles(){
      Swal.fire({
        title: "Loading roles.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
         window.serverPath+'dist/mvw/controllers/users.php',
        {
          params: {
            request: 'getUsersRoles',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.roles = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },

    getCountries(){
      Swal.fire({
        title: "Loading Countries",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'/dist/mvw/controllers/countries.php',
        {
          params: {
            request: 'getCountries',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.countries = response.data;
            Swal.close();
            if(this.$route.params.id){
              this.getStatesByCountryID();
            }
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },
    getStatesByCountryID(){
      let app = this;
      Swal.fire({
        title: "Loading States",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        window.serverPath+'/dist/mvw/controllers/states.php',
        {
          params: {
            request: 'getStatesByCountryID',
            idCountry: this.form.id_country,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if(response.status == 500){ app.$router.push({ name: 'Error-500' }); }
        if(response.status == 404){ app.$router.push({ name: 'Error-404' }); }
        if(response.status == 401){ app.$router.push({ name: 'Error-401' }); }

        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.states = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load States! Please load this module again.", "error");
        }
      });
    },




    // eslint-disable-next-line no-unused-vars
    saveCustomer(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_labor = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving labor.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/customers.php', {
          request: 'saveASCustomer',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            let action = "";
            if( response.data === "OK" ){
              action = "saved";
            }
            if( response.data === "UPDATED" ){
              action = "updated";
            }

            Swal.fire("Good job!", "The new customer is properly "+action+"!<br>Now they can use the system.<br>An email was sent to the provided email noticing the customer their account is already setup.", "success");
            app.$router.push({ name: 'labors' });
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }else{
        console.log(this.$v.form);
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div v-if="validAccess"  class="card-body">
            <form class="needs-validation" @submit.prevent="saveCustomer">
              <h4 class="card-title">Vertical Nav Tabs</h4>
              <p class="card-title-desc">Example of Vertical nav tabs</p>
              <b-tabs
                pills
                :vertical="(platform) ? false : true"
                nav-class="p-0"
                nav-wrapper-class="col-sm-3"
                content-class="pt-0 px-2 text-muted"
              >
                <b-tab title="Company" active title-item-class="mb-2">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="nm_company">Company name</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_company.$error }"
                          name="nm_company"
                          id="nm_company"
                          v-model="form.nm_company"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_company.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_company.required">Company name is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_companyShortAddress">Company short address</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_companyShortAddress.$error }"
                          name="de_companyShortAddress"
                          id="de_companyShortAddress"
                          v-model="form.de_companyShortAddress"
                          value=""
                        >
                        <span>City, State, Country. This will appear under Company > My Company</span>
                        <div v-if="submitted && $v.form.de_companyShortAddress.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_companyShortAddress.required">Company short address is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6"> 
                      <div class="form-group">
                        <label for="de_companyContactEmail">Company contact email</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_companyContactEmail.$error }"
                          name="de_companyContactEmail"
                          id="de_companyContactEmail"
                          v-model="form.de_companyContactEmail"
                          value=""
                          @change="setOtherEmails"
                        >
                        <span class="text-purple">City, State, Country</span>
                        <div v-if="submitted && $v.form.de_companyContactEmail.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_companyContactEmail.required">Company contact email is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="num_companyContactPhone">Company contact phone number</label>
                        <input
                          v-mask="'+(##) ###-###-####'"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.num_companyContactPhone.$error }"
                          name="num_companyContactPhone"
                          id="num_companyContactPhone"
                          v-model="form.num_companyContactPhone"
                        />
                        <span class="text-muted">e.g "(xx) xxxx-xxxx"</span>
                        <div v-if="submitted && $v.form.num_companyContactPhone.$error" class="invalid-feedback">
                          <span v-if="!$v.form.num_companyContactPhone.required">Company contact phone is required.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <b-tab title="Site" title-item-class="mb-2">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="id_country">Country</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_country.$error }"
                          name="id_country"
                          id="id_country"
                          v-model="form.id_country"
                          @change="getStatesByCountryID"
                        >
                          <option value="">SELECT YOUR COUNTRY</option>
                          <option v-for="(country, index) in countries" :key="index" :value="country.id_country">{{country.nm_country}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_country.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_country.required">Country field is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_state">State/Province</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_state.$error }"
                          name="id_state"
                          id="id_state"
                          v-model="form.id_state"
                          value=""
                        >
                          <option value="">SELECT YOUR STATE/PROVINCE</option>
                          <option v-for="(state, index) in states" :key="index" :value="state.id_state">{{state.nm_state}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_state.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_state.required">State/Province field is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_address">Address</label>
                        <textarea
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_address.$error }"
                          name="de_address"
                          id="de_address"
                          v-model="form.de_address"
                          rows="3"
                          value=""
                        >
                        </textarea>
                        <span>Full address for invoicing</span>
                        <div v-if="submitted && $v.form.de_address.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_address.required">Address field is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="de_siteRepresentantEmail">Representant email</label>
                        <input
                          type="email"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_siteRepresentantEmail.$error }"
                          name="de_siteRepresentantEmail"
                          id="de_siteRepresentantEmail"
                          v-model="form.de_siteRepresentantEmail"
                          value=""
                        >
                        <div v-if="submitted && $v.form.de_siteRepresentantEmail.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_siteRepresentantEmail.required">Representant email is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_siteHumanResourcesEmail">Human Resources email</label>
                        <input
                          type="email"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_siteHumanResourcesEmail.$error }"
                          name="de_siteHumanResourcesEmail"
                          id="de_siteHumanResourcesEmail"
                          v-model="form.de_siteHumanResourcesEmail"
                          value=""
                        >
                        <div v-if="submitted && $v.form.de_siteHumanResourcesEmail.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_siteHumanResourcesEmail.required">Human Resources email is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_siteContactEmail">Contact email</label>
                        <input
                          type="email"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_siteContactEmail.$error }"
                          name="de_siteContactEmail"
                          id="de_siteContactEmail"
                          v-model="form.de_siteContactEmail"
                          value=""
                        >
                        <div v-if="submitted && $v.form.de_siteContactEmail.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_siteContactEmail.required">Contact email is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="nm_site">Site name</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_site.$error }"
                          name="nm_site"
                          id="nm_site"
                          v-model="form.nm_site"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_site.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_site.required">Site name is required.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <b-tab title="Employee" title-item-class="mb-2">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="nm_employee">Employee name</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_employee.$error }"
                          name="nm_employee"
                          id="nm_employee"
                          v-model="form.nm_employee"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_employee.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_employee.required">Employee name is required.</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="nm_lastName">Employee last name</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_lastName.$error }"
                          name="nm_lastName"
                          id="nm_lastName"
                          v-model="form.nm_lastName"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_lastName.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_lastName.required">Employee last name is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="num_employeeAge">Employee age</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.num_employeeAge.$error }"
                          name="num_employeeAge"
                          id="num_employeeAge"
                          v-model="form.num_employeeAge"
                          value=""
                        />
                        <div v-if="submitted && $v.form.num_employeeAge.$error" class="invalid-feedback">
                          <span v-if="!$v.form.num_employeeAge.required">Employee age is required.</span>
                          <span v-if="!$v.form.num_employeeAge.minValue">This minimum age is invalid. It should be between 18 and 100 years old.</span>
                          <span v-if="!$v.form.num_employeeAge.maxValue">This maximum age is invalid. It should be between 18 and 100 years old.</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="id_gender">Gender</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_gender.$error }"
                          name="id_gender"
                          id="id_gender"
                          v-model="form.id_gender"
                        >
                          <option value="">SELECT EMPLOYEE GENDER</option>
                          <option value="0">FEMALE</option>
                          <option value="1">MALE</option>
                          <option value="2">NONE BINARY</option>
                        </select>
                        <div v-if="submitted && $v.form.id_gender.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_gender.required">Gender is required.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <b-tab title="User" title-item-class="mb-2">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="id_role">Role</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_role.$error }"
                          name="id_role"
                          id="id_role"
                          v-model="form.id_role"
                        >
                          <option value="">SELECT USER ROLE</option>
                          <option v-for="(role, index) in roles" :key="index" :value="role.id_role">{{role.nm_role}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_role.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_role.required">Site is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="de_password">Password</label>
                        <input
                          v-model="form.de_password"
                          type="password"
                          name="de_password"
                          class="form-control"
                          :class="{ 'is-invalid': (submitted && $v.form.de_password.$error) || (!okPassword) }"
                          placeholder="Password"
                          v-on:keypress="validatePassword"
                        />
                        <div v-if="(submitted && $v.form.de_password.$error) || (!okPassword)" class="invalid-feedback">
                          <span v-if="!$v.form.de_password.required">This value is required.</span>
                          <span v-if="!$v.form.de_password.minLength">Password must be at least / characters.</span>
                          <span v-if="!okPassword">
                            Password must content at least 1 Capital, 1 minus, 1 digit and at least 1 of those simbols #?!@$%^&*-
                          </span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_password">Repeat Password</label>
                        <input
                          v-model="form.de_rePassword"
                          type="password"
                          name="de_rePassword"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_rePassword.$error }"
                          placeholder="Re-Type Password"
                        />

                        <div
                          v-if="submitted && $v.form.de_rePassword.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.de_rePassword.required">Password is required.</span>
                          <span
                            v-else-if="!$v.form.de_rePassword.sameAsPassword"
                          >This value should be the same.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>

              </b-tabs>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">SAVE SHOP CLIENT</button>
                    <router-link to="/shop/labors" class="ml-3">
                      <button class="btn btn-danger" type="reset">CANCEL </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </Layout>
</template>

<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
</style>